import { handleCall } from './handleResponse'

import {
  isManager,
  isOperatorManager,
  isSupervisor,
  isTechnician,
  isOperator,
} from '../helpers/roles'
import { COUNTRY_ADMIN_FF } from '../constants/global_constants'

export const swapsService = {
  getAll,
  getById,
  proposeReservation,
  createReservation,
  createSwapManual,
  getForManualSwapTicketAsync,
}

function getById(id) {
  return handleCall(`/stations/v1/swaps/${id}/GetByIdForNoCustomer`, {
    method: 'GET',
    body: null,
  })
}

function getForManualSwapTicketAsync(ticketId) {
  return handleCall(
    `/stations/v1/swaps/GetForManualSwapTicket?ticketId=${ticketId}`,
    {
      method: 'GET',
      body: null,
    },
  )
}

function proposeReservation(stationId, batteriesNumber, customerEmail, rows) {
  return handleCall(
    `/stations/v1/swaps/ProposeReservation?stationId=${stationId}&batteriesNumber=${batteriesNumber}&customerEmail=${encodeURIComponent(customerEmail)}${rows && rows.length > 0 ? `&rows=${rows.map(r => r.letter).join(',')}` : ''}`,
    {
      method: 'GET',
      body: null,
    },
  )
}

function createReservation(customerEmail, body) {
  return handleCall(
    `/stations/v1/swaps/CreateReservation?customerEmail=${encodeURIComponent(customerEmail)}`,
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
  )
}

function createSwapManual(body) {
  return handleCall(`/stations/v1/swaps/ManualSwap`, {
    method: 'POST',
    body: JSON.stringify(body),
  })
}

function getAll({
  zoneId = null,
  customerId = null,
  stationId = null,
  since = null,
  until = null,
}) {
  let endpoint = ''

  if (isManager()) endpoint = ''
  if (isOperatorManager() || (isOperator() && !COUNTRY_ADMIN_FF)) endpoint = 'GetForOperator'
  if (isTechnician()) endpoint = 'GetForTechnician'
  if (isSupervisor()) endpoint = 'GetForSupervisor'

  let params = ''
  if (since && until) params = `?since=${since}&until=${until}`
  if (zoneId) params = `?zoneId=${zoneId}`
  if (customerId) params = `?customerId=${customerId}`
  if (stationId) params = `?stationId=${stationId}`

  return handleCall(`/stations/v1/Swaps/${endpoint}${params}`, {
    method: 'GET',
    body: null,
  })
}
