import { stationsConstants } from '../../constants/stations_constants'
import { operatorService } from '../../services/operatorService'
import { stationsService } from '../../services/stationsServices'
import { alertActions } from './alert_actions'

import i18next from 'i18next'
import moment from 'moment'
import { getOperatorId } from '../../helpers/operator'
import {
  isAltechOperations,
  isCauUser,
  isCountryAdmin,
  isFinancial,
  isManager,
  isManagerReadOnly,
  isOperatorManager,
  isSacUser,
  isSupervisor,
  isTechnician,
} from '../../helpers/roles'

const { getForTechnician } = operatorService

export const stationsActions = {
  getAllStations,
  getStationById,
  createStation,
  getAllDynamics,
  getDynamicsById,
  getStations,
  getStationsAssignedToZone,
  getStationsAssignedToOperator,
  getStationsAssignedToSupervisor,
  create,
  assignToZone,
  assignToOperator,
  unassignFromOperator,
  getAllUnassignedStations,
  getUnassignedByZone,
  unassignFromZoneAndOperatorCia,
  getAllUnassignedZonesAndOperatorCia,
  assignToZoneAndOperator,
  getUnassignedsToOperatorCiaByZones,
  setOutOfService,
  deleteStation,
  updateStation,
  getToBatteriesDeliveryForAdmin,
  createBatteryExtraction,
  unlockSlot,
  getWeekDays,
  getForLogged,
}

function getStations() {
  return (dispatch) => {
    isTechnician() ? getForTechnician() : dispatch(getAllStations())
  }
}

function updateStation(body) {
  return (dispatch) => {
    stationsService.updateStation(body).then(
      (results) => {
        dispatch(getStations())
        dispatch(
          alertActions.success(
            i18next.t('services.stations.updateStationSuccess'),
          ),
        )
      },
      (error) => {
        dispatch(alertActions.error(error))
      },
    )
  }
}

function deleteStation(id) {
  return (dispatch) => {
    stationsService.deleteStation(id).then(
      (results) => {
        dispatch(getStations())
        dispatch(
          alertActions.success(
            i18next.t('services.stations.deleteStationSuccess'),
          ),
        )
      },
      (error) => {
        dispatch(
          alertActions.error(i18next.t('services.stations.deleteStationError')),
        )
      },
    )
  }
}

function setOutOfService(body) {
  const { id } = body
  return (dispatch) => {
    stationsService.setOutOfService(body).then(
      (results) => {
        dispatch(getStations())
        dispatch(getStationById(id))
        dispatch(getDynamicsById(id))
        dispatch(getForLogged())
        dispatch(
          alertActions.success(
            i18next.t('services.stations.setOutOfServiceSuccess'),
          ),
        )
      },
      (error) => {
        dispatch(
          alertActions.error(
            i18next.t('services.stations.setOutOfServiceError'),
          ),
        )
      },
    )
  }
}

function getUnassignedsToOperatorCiaByZones(filter) {
  return (dispatch) => {
    dispatch(request())
    stationsService.getUnassignedsToOperatorCiaByZones(filter).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        // dispatch(
        //   alertActions.error("error get all operator action. Message:" + error)
        // );
      },
    )
  }

  function request() {
    return {
      type: stationsConstants.GET_ALL_UNASSIGNED_TO_ZONE_AND_OPERATORCIA_REQUEST,
    }
  }

  function success(results) {
    return {
      type: stationsConstants.GET_ALL_UNASSIGNED_TO_ZONE_AND_OPERATORCIA_SUCCESS,
      results,
    }
  }

  function failure(error) {
    return {
      type: stationsConstants.GET_ALL_UNASSIGNED_TO_ZONE_AND_OPERATORCIA_FAILURE,
      error,
    }
  }
}

function assignToZoneAndOperator(body) {
  return (dispatch) => {
    stationsService.assignToZoneAndOperator(body).then(
      (results) => {
        dispatch(getStations())
        dispatch(
          alertActions.success(
            i18next.t('services.stations.assignToOperatorSuccess'),
          ),
        )
      },
      (error) => {
        dispatch(
          alertActions.error(
            `${i18next.t('services.stations.assignToOperatorError')}: ${error}`,
          ),
        )
      },
    )
  }
}

function getAllUnassignedZonesAndOperatorCia() {
  return (dispatch) => {
    dispatch(request())
    stationsService.getAllUnassignedZonesAndOperatorCia().then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        // dispatch(
        //   alertActions.error("error get all operator action. Message:" + error)
        // );
      },
    )
  }

  function request() {
    return {
      type: stationsConstants.GET_ALL_UNASSIGNED_BY_ZONE_AND_OPERATORCIA_REQUEST,
    }
  }

  function success(results) {
    return {
      type: stationsConstants.GET_ALL_UNASSIGNED_BY_ZONE_AND_OPERATORCIA_SUCCESS,
      results,
    }
  }

  function failure(error) {
    return {
      type: stationsConstants.GET_ALL_UNASSIGNED_BY_ZONE_AND_OPERATORCIA_FAILURE,
      error,
    }
  }
}

function unassignFromZoneAndOperatorCia(body) {
  const { zoneId } = body
  delete body.zoneId
  return (dispatch) => {
    stationsService.unassignFromZoneAndOperatorCia(body).then(
      (results) => {
        dispatch(getStations())
        dispatch(getAllUnassignedZonesAndOperatorCia())
        dispatch(getStationsAssignedToZone(zoneId))
        dispatch(
          alertActions.success(
            i18next.t(
              'services.stations.unassignFromZoneAndOperatorCiaSuccess',
            ),
          ),
        )
      },
      (error) => {
        dispatch(
          alertActions.error(
            `${i18next.t(
              'services.stations.unassignFromZoneAndOperatorCiaError',
            )}: ${error}`,
          ),
        )
      },
    )
  }
}

function getUnassignedByZone(body) {
  return (dispatch) => {
    dispatch(request())
    stationsService.getUnassignedByZone(body).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        // dispatch(
        //   alertActions.error("error get all operator action. Message:" + error)
        // );
      },
    )
  }

  function request() {
    return { type: stationsConstants.GET_UNASSIGNED_BY_ZONE_REQUEST }
  }

  function success(results) {
    return { type: stationsConstants.GET_UNASSIGNED_BY_ZONE_SUCCESS, results }
  }

  function failure(error) {
    return { type: stationsConstants.GET_UNASSIGNED_BY_ZONE_FAILURE, error }
  }
}

function getWeekDays(body) {
  return (dispatch) => {
    dispatch(request())
    stationsService.getWeekDays().then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: stationsConstants.GET_WEEK_DAYS_REQUEST }
  }

  function success(results) {
    return { type: stationsConstants.GET_WEEK_DAYS_SUCCESS, results }
  }

  function failure(error) {
    return { type: stationsConstants.GET_WEEK_DAYS_FAILURE, error }
  }
}

function getAllUnassignedStations() {
  return (dispatch) => {
    dispatch(request())
    stationsService.getAllUnassignedStations().then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        // dispatch(
        //   alertActions.error("error get all operator action. Message:" + error)
        // );
      },
    )
  }

  function request() {
    return { type: stationsConstants.GET_ALL_UNASSIGNED_REQUEST }
  }

  function success(results) {
    return { type: stationsConstants.GET_ALL_UNASSIGNED_SUCCESS, results }
  }

  function failure(error) {
    return { type: stationsConstants.GET_ALL_UNASSIGNED_FAILURE, error }
  }
}

function assignToZone(body) {
  const { zoneId } = body
  return (dispatch) => {
    stationsService.assignToZone(body).then(
      (results) => {
        dispatch(getAllUnassignedZonesAndOperatorCia())
        dispatch(getStationsAssignedToZone(zoneId))
        dispatch(
          alertActions.success(
            i18next.t('services.stations.assignToZoneSuccess'),
          ),
        )
      },
      (error) => {
        dispatch(
          alertActions.error(
            `${i18next.t('services.stations.assignToZoneError')}: ${error}`,
          ),
        )
      },
    )
  }
}

function assignToOperator(body) {
  const { operatorCiaId } = body
  return (dispatch) => {
    stationsService.assignToOperator(body).then(
      (results) => {
        dispatch(getStations())
        dispatch(getAllUnassignedStations())
        dispatch(getStationsAssignedToOperator(operatorCiaId))
        dispatch(
          alertActions.success(
            i18next.t('services.stations.assignToOperatorSuccess'),
          ),
        )
      },
      (error) => {
        dispatch(
          alertActions.error(
            `${i18next.t('services.stations.assignToOperatorError')}: ${error}`,
          ),
        )
      },
    )
  }
}

function unassignFromOperator(body) {
  const { operatorCiaId } = body
  delete body.operatorCiaId
  return (dispatch) => {
    stationsService.unassignFromOperator(body).then(
      (results) => {
        dispatch(getStations())
        dispatch(getAllUnassignedStations())
        dispatch(getStationsAssignedToOperator(operatorCiaId))
        dispatch(
          alertActions.success(
            i18next.t('services.stations.unassignFromOperatorSuccess'),
          ),
        )
      },
      (error) => {
        dispatch(
          alertActions.error(
            `${i18next.t(
              'services.stations.unassignFromOperatorError',
            )}: ${error}`,
          ),
        )
      },
    )
  }
}

function create(body) {
  return (dispatch) => {
    stationsService.create(body).then(
      (results) => {
        dispatch(getStations())
        dispatch(
          alertActions.success(i18next.t('services.stations.createSuccess')),
        )
      },
      (error) => {
        dispatch(alertActions.error(error))
      },
    )
  }
}

function getStationsAssignedToSupervisor(supervisorId) {
  return (dispatch) => {
    dispatch(request())
    stationsService.getStationsAssignedToSupervisor(supervisorId).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return {
      type: stationsConstants.GET_STATIONS_ASSIGNED_TO_OPERATOR_REQUEST,
    }
  }

  function success(results) {
    return {
      type: stationsConstants.GET_STATIONS_ASSIGNED_TO_OPERATOR_SUCCESS,
      results,
    }
  }

  function failure(error) {
    return {
      type: stationsConstants.GET_STATIONS_ASSIGNED_TO_OPERATOR_FAILURE,
      error,
    }
  }
}

function getStationsAssignedToOperator(operatorId) {
  return (dispatch) => {
    dispatch(request())
    stationsService
      .getStationsAssignedToOperator(
        isOperatorManager() ? getOperatorId() : operatorId,
      )
      .then(
        (results) => {
          results.map((station) => {
            station.infoPointSchedule = station.infoPointSchedule?.map(
              (item) => {
                return {
                  ...item,
                  from: moment.utc(item.from, 'HH:mm').local().format('HH:mm'),
                  to: moment.utc(item.to, 'HH:mm').local().format('HH:mm'),
                }
              },
            )
            station.stationSchedule = station.stationSchedule?.map((item) => {
              return {
                ...item,
                from: moment.utc(item.from, 'HH:mm').local().format('HH:mm'),
                to: moment.utc(item.to, 'HH:mm').local().format('HH:mm'),
              }
            })
          })
          dispatch(success(results))
        },
        (error) => {
          dispatch(failure(error))
        },
      )
  }

  function request() {
    return {
      type: stationsConstants.GET_STATIONS_ASSIGNED_TO_OPERATOR_REQUEST,
    }
  }

  function success(results) {
    return {
      type: stationsConstants.GET_STATIONS_ASSIGNED_TO_OPERATOR_SUCCESS,
      results,
    }
  }

  function failure(error) {
    return {
      type: stationsConstants.GET_STATIONS_ASSIGNED_TO_OPERATOR_FAILURE,
      error,
    }
  }
}

function getStationsAssignedToZone(zoneId) {
  return (dispatch) => {
    dispatch(request())
    stationsService.getStationsAssignedToZone(zoneId).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: stationsConstants.GET_STATIONS_ASSIGNED_TO_ZONE_REQUEST }
  }

  function success(results) {
    return {
      type: stationsConstants.GET_STATIONS_ASSIGNED_TO_ZONE_SUCCESS,
      results,
    }
  }

  function failure(error) {
    return {
      type: stationsConstants.GET_STATIONS_ASSIGNED_TO_ZONE_FAILURE,
      error,
    }
  }
}

function getDynamicsById(stationId, notify = false) {
  return (dispatch) => {
    dispatch(request())
    stationsService.getDynamicsById(stationId).then(
      (results) => {
        dispatch(success(results))
        notify &&
          dispatch(
            alertActions.success(
              i18next.t('services.stations.stationDataRefreshSuccess'),
            ),
          )
      },
      (error) => {
        dispatch(failure(error))
        notify &&
          dispatch(
            alertActions.error(
              i18next.t('services.stations.stationDataRefreshError'),
            ),
          )
      },
    )
  }

  function request() {
    return { type: stationsConstants.GET_DYNAMICS_BY_STATION_ID_REQUEST }
  }

  function success(results) {
    return {
      type: stationsConstants.GET_DYNAMICS_BY_STATION_ID_SUCCESS,
      results,
    }
  }

  function failure(error) {
    return {
      type: stationsConstants.GET_DYNAMICS_BY_STATION_ID_FAILURE,
      error,
    }
  }
}

function getAllDynamics() {
  return (dispatch) => {
    dispatch(request())
    stationsService.getAllDynamics().then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
        // dispatch(
        //   alertActions.error("error get all operator action. Message:" + error)
        // );
      },
    )
  }

  function request() {
    return { type: stationsConstants.GET_ALL_DYNAMICS_REQUEST }
  }

  function success(results) {
    return { type: stationsConstants.GET_ALL_DYNAMICS_SUCCESS, results }
  }

  function failure(error) {
    return { type: stationsConstants.GET_ALL_DYNAMICS_FAILURE, error }
  }
}

function createStation(body) {
  return (dispatch) => {
    // dispatch(request());
    stationsService.createStation(body).then(
      (results) => {
        // success(results);
        dispatch(getStations())
      },
      (error) => {
        // dispatch(failure(error));
      },
    )
  }
}

function getStationById(stationId) {
  return (dispatch) => {
    dispatch(request())
    stationsService.getStationById(stationId).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: stationsConstants.GET_BY_STATION_ID_REQUEST }
  }

  function success(results) {
    return { type: stationsConstants.GET_BY_STATION_ID_SUCCESS, results }
  }

  function failure(error) {
    return { type: stationsConstants.GET_BY_STATION_ID_FAILURE, error }
  }
}

function getAllStations() {
  return (dispatch) => {
    if (
      isManager() ||
      isSacUser() ||
      isCauUser() ||
      isAltechOperations() ||
      isManagerReadOnly() ||
      isCountryAdmin() ||
      isSupervisor() ||
      isFinancial()
    ) {
      dispatch(request())
      stationsService.getAllStations().then(
        (results) => {
          results.map((station) => {
            station.infoPointSchedule = station.infoPointSchedule?.map(
              (item) => {
                return {
                  ...item,
                  from: moment.utc(item.from, 'HH:mm').local().format('HH:mm'),
                  to: moment.utc(item.to, 'HH:mm').local().format('HH:mm'),
                }
              },
            )
            station.stationSchedule = station.stationSchedule?.map((item) => {
              return {
                ...item,
                from: moment.utc(item.from, 'HH:mm').local().format('HH:mm'),
                to: moment.utc(item.to, 'HH:mm').local().format('HH:mm'),
              }
            })
          })
          dispatch(success(results))
        },
        (error) => {
          dispatch(failure(error))
        },
      )
    } else {
      dispatch(getStationsAssignedToOperator(getOperatorId()))
    }
  }

  function request() {
    return { type: stationsConstants.GET_ALL_REQUEST }
  }

  function success(results) {
    return { type: stationsConstants.GET_ALL_SUCCESS, results }
  }

  function failure(error) {
    return { type: stationsConstants.GET_ALL_FAILURE, error }
  }
}

function getForLogged() {
  return (dispatch) => {
    dispatch(request())
    stationsService.getForLogged().then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: stationsConstants.GET_STATIONS_FOR_LOGGED_REQUEST }
  }

  function success(results) {
    return { type: stationsConstants.GET_STATIONS_FOR_LOGGED_SUCCESS, results }
  }

  function failure(error) {
    return { type: stationsConstants.GET_STATIONS_FOR_LOGGED_FAILURE, error }
  }
}

function getToBatteriesDeliveryForAdmin() {
  return (dispatch) => {
    dispatch(request())
    stationsService.getToBatteriesDeliveryForAdmin().then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return {
      type: stationsConstants.GET_TO_BATTERIES_DELIVERY_FOR_ADMIN_REQUEST,
    }
  }

  function success(results) {
    return {
      type: stationsConstants.GET_TO_BATTERIES_DELIVERY_FOR_ADMIN_SUCCESS,
      results,
    }
  }

  function failure(error) {
    return {
      type: stationsConstants.GET_TO_BATTERIES_DELIVERY_FOR_ADMIN_FAILURE,
      error,
    }
  }
}

function createBatteryExtraction(body) {
  return (dispatch) => {
    stationsService.createBatteryExtraction(body).then(
      () => {
        dispatch(
          alertActions.success(
            i18next.t('services.stations.unlockSlotSuccess'),
          ),
        )
      },
      (error) => {
        // 499 Response case
        if (typeof error === 'string') {
          dispatch(alertActions.error(error))
        } else {
          dispatch(
            alertActions.error(i18next.t('services.stations.unlockSlotError')),
          )
        }
      },
    )
  }
}

function unlockSlot(id, body) {
  return (dispatch) => {
    stationsService.unlockSlot(id, body).then(
      () => {
        dispatch(
          alertActions.success(
            i18next.t('services.stations.unlockSlotSuccess'),
          ),
        )
      },
      (error) => {
        // 499 Response case
        if (typeof error === 'string') {
          dispatch(alertActions.error(error))
        } else {
          dispatch(
            alertActions.error(i18next.t('services.stations.unlockSlotError')),
          )
        }
      },
    )
  }
}
