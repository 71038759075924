import { userConstants } from '../../constants/user_constants'
import { history } from '../../helpers/history'
import { loginService } from '../../services/loginService'
import { alertActions } from './alert_actions'

import { detectMaxRole, rolesConstants } from '../../helpers/roles'

import { userService } from '../../services/userService'

import { operatorActions } from './operator_action'
import { supervisorsActions } from './supervisor_action'

import i18next from 'i18next'
import { COUNTRY_ADMIN_FF } from '../../constants/global_constants'
export const userActions = {
  login,
  totp,
  getUserInfo,
  refreshToken,
  logout,
  checkToken,
  selectRole,
  insertSilenceUser,
  changePassword,
  resetPassword,
}

function changePassword(body) {
  return (dispatch) => {
    userService.changePassword(body).then(
      (user) => {
        dispatch(
          alertActions.success(
            i18next.t('services.user.changePasswordSuccess'),
          ),
        )
      },
      (error) => {
        dispatch(
          alertActions.error(
            i18next.t('services.user.changePasswordError') + error,
          ),
        )
      },
    )
  }
}

function resetPassword(body, callback = null) {
  return (dispatch) => {
    userService.resetPassword(body).then(
      (user) => {
        dispatch(alertActions.success(i18next.t('services.user.resetPassword')))
        if (callback) callback()
      },
      (error) => {
        dispatch(alertActions.success(i18next.t('services.user.resetPassword')))
        if (callback) callback()
      },
    )
  }
}

function insertSilenceUser(body) {
  return (dispatch) => {
    userService.insertSilenceUser(body).then(
      (user) => {
        dispatch(
          alertActions.success(
            i18next.t('services.user.insertSilenceUserSuccess'),
          ),
        )
      },
      (error) => {
        dispatch(
          alertActions.error(
            i18next.t('services.user.insertSilenceUserError'),
            error,
          ),
        )
      },
    )
  }
}

function selectRole(userSelected, redirect) {
  return (dispatch) => {
    const selectedRole = detectMaxRole(userSelected.roles)

    switch (selectedRole) {
      case rolesConstants.BS_ADMIN:
        dispatch(selectedSuccess(userSelected, selectedRole))
        if (redirect) history.push('/home')
        //
        break
      case rolesConstants.BS_OPERATOR_MANAGER:
        dispatch(selectedSuccess(userSelected, selectedRole))
        const { operatorUser } = userSelected
        dispatch(operatorActions.getOperatorById(operatorUser.operatorCiaId))
        if (redirect) history.push('/requestBattery')
        //
        break
      case (COUNTRY_ADMIN_FF ? rolesConstants.BS_COUNTRY_ADMIN : rolesConstants.BS_OPERATOR):
        dispatch(selectedSuccess(userSelected, selectedRole))
        if (redirect) history.push('/home')
        //
        break

      case rolesConstants.BS_SUPERVISOR:
        dispatch(selectedSuccess(userSelected, selectedRole))
        const { supervisor } = userSelected
        dispatch(supervisorsActions.getSupervisorById(supervisor.id))
        if (redirect) history.push('/home')
        //
        break

      case rolesConstants.BS_TECHNICIAN:
        dispatch(selectedSuccess(userSelected, selectedRole))
        if (redirect) history.push('/incidents')
        //
        break
      case rolesConstants.BS_FINANCIAL:
        dispatch(selectedSuccess(userSelected, selectedRole))
        if (redirect) history.push('/batteries')
        //
        break
      case rolesConstants.ALTECH_OPERATIONS:
        dispatch(selectedSuccess(userSelected, selectedRole))
        if (redirect) history.push('/stations')
        //
        break
      case rolesConstants.BS_ADMINREADONLY:
        dispatch(selectedSuccess(userSelected, selectedRole))
        if (redirect) history.push('/home')
        break
      case rolesConstants.CAU_USER:
        dispatch(selectedSuccess(userSelected, selectedRole))
        if (redirect) history.push('/map')
        break
      case rolesConstants.SAC_USER:
        dispatch(selectedSuccess(userSelected, selectedRole))
        if (redirect) history.push('/home')
        break
      default:
        dispatch(
          alertActions.error(i18next.t('services.user.selectRoleNotAllowed')),
        )
        dispatch(selectedSuccess({ role: null }, null))
        dispatch(logout())
        break
    }

    function selectedSuccess(userSelected, selectedRole) {
      return {
        type: userConstants.SELECTED_USER_SUCCESS,
        userSelected,
        selectedRole,
      }
    }
  }
}
function checkToken() {
  return (dispatch) => {
    const bs_token = JSON.parse(localStorage.getItem('bs_token')) || {
      value: null,
    }

    if (!bs_token.value) {
      history.push('/login')
    } else {
      dispatch(successToken(bs_token))
      dispatch(getUserInfo(false))
    }
    function successToken(token) {
      return { type: userConstants.TOKEN_SUCCESS, token }
    }
    function selectedSuccess(userSelected) {
      return { type: userConstants.SELECTED_USER_SUCCESS, userSelected }
    }
  }
}

function login(email, password, isRememberPasswordChecked) {
  return (dispatch) => {
    dispatch(requestLogin())
    var locale = findGetParameter('bs_locale')
    localStorage.setItem('bs_locale', locale)
    dispatch(requestToken)
    loginService.token(email, password).then(
      (token) => {
        if (token && token.hasOwnProperty('error')) {
          dispatch(
            alertActions.error(
              i18next.t('services.user.loginIncorrectPasswdMail'),
              token.error.message,
            ),
          )
          dispatch(failureToken(token.error))
        } else {
          dispatch(successToken(token))
          dispatch(getUserInfo(true))
        }
      },
      (error) => {
        dispatch(alertActions.error('Error Login: ', error))
        dispatch(failureToken(error))
      },
    )
    function selectedSuccess(userSelected) {
      return { type: userConstants.SELECTED_USER_SUCCESS, userSelected }
    }
    function requestLogin() {
      return { type: userConstants.LOGIN_REQUEST }
    }
    function requestToken() {
      return { type: userConstants.TOKEN_REQUEST }
    }
    function successToken(token) {
      return { type: userConstants.TOKEN_SUCCESS, token }
    }
    function failureToken(error) {
      return { type: userConstants.TOKEN_FAILURE, error }
    }
  }
}

function totp(email, password, code) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(requestLogin())
      const locale = findGetParameter('bs_locale')
      localStorage.setItem('bs_locale', locale)
      dispatch(requestToken)
      loginService.token(email, password, code).then(
        (token) => {
          if (token) {
            if (token.hasOwnProperty('error')) {
              dispatch(
                alertActions.error(
                  i18next.t('services.user.loginIncorrectPasswdMail'),
                ),
              )
              dispatch(failureToken(token.error))
              reject(token.error)
            } else if (token.hasOwnProperty('code')) {
              dispatch(failureToken(token))
              reject(token)
            } else {
              dispatch(successToken(token))
              dispatch(getUserInfo(true))
              resolve(token)
            }
          } else {
            dispatch(successToken(token))
            dispatch(getUserInfo(true))
            resolve(token)
          }
        },
        (error) => {
          dispatch(alertActions.error('Error Login', error))
          dispatch(failureToken(error))
          reject(error)
        },
      )
    })

  function requestLogin() {
    return { type: userConstants.LOGIN_REQUEST }
  }
  function successLogin() {
    return { type: userConstants.LOGIN_SUCCESS }
  }
  function requestToken() {
    return { type: userConstants.TOKEN_REQUEST }
  }
  function successToken(token) {
    return { type: userConstants.TOKEN_SUCCESS, token }
  }
  function failureToken(error) {
    return { type: userConstants.TOKEN_FAILURE, error }
  }
}

function findGetParameter(parameterName) {
  var result = null,
    tmp = []
  var items = window.location.search.substr(1).split('&')
  for (var index = 0; index < items.length; index++) {
    tmp = items[index].split('=')
    if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1])
  }
  return result
}

function refreshToken(token) {
  return (dispatch) => {
    dispatch(request(token))
    loginService.refreshToken(token).then(
      (token) => {
        dispatch(success(token.access_token))
      },
      (error) => {
        dispatch(failure(error.toString()))
      },
    )
  }

  function request(token) {
    return { type: userConstants.REFRESH_REQUEST, token }
  }

  function success(token) {
    return { type: userConstants.REFRESH_SUCCESS, token }
  }

  function failure(error) {
    return { type: userConstants.REFRESH_FAILURE, error }
  }
}

function getUserInfo(redirect) {
  return (dispatch) => {
    dispatch(requestUserInfo())

    loginService.userInfo().then(
      (user) => {
        dispatch(successUserInfo(user))
        dispatch(selectRole(user, redirect))
      },
      (error) => {
        // dispatch(alertActions.error("Error get info user: " + error));
      },
    )

    function requestUserInfo(token) {
      return { type: userConstants.INFO_REQUEST, token }
    }
    function successUserInfo(user) {
      return { type: userConstants.INFO_SUCCESS, user }
    }
    function successLogin() {
      return { type: userConstants.LOGIN_SUCCESS }
    }
  }
}

function logout() {
  return (dispatch) => {
    dispatch(request())
    loginService.logout()
    history.push('/login')
  }

  function request() {
    return { type: userConstants.LOGOUT }
  }
}
