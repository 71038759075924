import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Slide from '@material-ui/core/Slide'
import { makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { Grid } from '@material-ui/core'
import GoogleMapReact from 'google-map-react'
import stringConstants from '../../../constants/strings'
import { stationsActions } from '../../../redux/actions/stations_actions'
import StationInfo from '../pagesDetails/stationInfo'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  mainContainer: {
    padding: '2%',
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const AppUrl = process.env.PUBLIC_URL

const Marker = ({ children }) => children

const StationDetails = ({
  isOpen,
  onClose,
  stationsReducer,
  getDynamicsById,
  createBatteryExtraction,
  unlockSlot,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [selectedIndex, setSelectedIndex] = useState(0)
  const [stationInfo, setStationInfo] = useState({})
  const [selectedSlots, setSelectedSlots] = useState()

  useEffect(() => {
    setStationInfo(stationsReducer.dynamic)
    // !selectedIndex && setSelectedIndex(0)
  }, [stationsReducer.dynamic])

  const handleClose = () => {
    setSelectedIndex(0)
    setStationInfo({})
    setSelectedSlots([])

    onClose()

    window.location.href = '/stations'
  }

  const refreshStationData = () => {
    const { id } = stationsReducer.dynamic
    const sendAlert = true
    getDynamicsById(id, sendAlert)
  }

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar position="relative">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {t('station.dialogs.stationDetails.dialogTitle')}
          </Typography>
          <Button autoFocus color="inherit" onClick={refreshStationData}>
            {t('station.dialogs.stationDetails.refreshData')}
          </Button>
        </Toolbar>
      </AppBar>

      <Grid
        container
        spacing={2}
        style={{
          width: '100%',
          padding: 32,
          margin: 0,
        }}
      >
        <Grid
          item
          container
          spacing={2}
          style={{
            padding: 0,
            marginBottom: 16,
          }}
        >
          <Grid item xs={12} sm={6} md={4}>
            <List dense={true} style={{ padding: 0 }}>
              <ListItem style={{ backgroundColor: '#e41622', color: 'white' }}>
                <ListItemText
                  primary={`${t('station.details.rows.zone')}: ${
                    stationInfo.zone
                  }`}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={`${t('station.details.rows.operator')}: ${
                    stationInfo.operatorCia
                  }`}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={`${t('station.details.rows.stationId')}: ${
                    stationInfo.id
                  }`}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={`${t('station.details.rows.numberOfRows')}: ${
                    stationInfo.rowsData?.length ?? 0
                  }`}
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <List dense={true} style={{ padding: 0 }}>
              <ListItem style={{ backgroundColor: '#e41622', color: 'white' }}>
                <ListItemText
                  primary={`${t('station.details.rows.location')}: ${
                    stationInfo.address
                  }`}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={`${t('station.details.rows.locationName')}: ${
                    stationInfo.name
                  }`}
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} sm={12} md={4} style={{ minHeight: 150 }}>
            {stationInfo?.location?.longitude &&
              stationInfo?.location?.latitude && (
                <GoogleMapReact
                  yesIWantToUseGoogleMapApiInternals
                  bootstrapURLKeys={{
                    key: stringConstants.GOOGLE_API_KEY,
                    libraries: ['drawing'],
                  }}
                  defaultCenter={{
                    lat: parseFloat(stationInfo.location.latitude),
                    lng: stationInfo.location.longitude,
                  }}
                  defaultZoom={10}
                >
                  <Marker
                    lat={stationInfo.location.latitude}
                    lng={stationInfo.location.longitude}
                  >
                    <div
                      className="station-marker"
                      style={{ marginTop: '-34px', marginLeft: '-17px' }}
                    >
                      <img
                        style={{ width: '25px', cursor: 'pointer' }}
                        src={`${AppUrl}/content/icons/slot.png`}
                        alt="station icon"
                      />
                    </div>
                  </Marker>
                </GoogleMapReact>
              )}
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ marginTop: 10 }}>
          <StationInfo
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            rowsData={stationsReducer.dynamic.rowsData}
            stationRows={stationsReducer.station.rows}
            stationId={stationInfo.id}
            selectedSlots={selectedSlots}
            setSelectedSlots={setSelectedSlots}
            createBatteryExtraction={createBatteryExtraction}
            unlockSlot={unlockSlot}
            outOfService={stationInfo.outOfService}
          />
        </Grid>
      </Grid>
    </Dialog>
  )
}

function mapState(state) {
  const { stationsReducer } = state
  return { stationsReducer }
}

const actionCreators = {
  createBatteryExtraction: stationsActions.createBatteryExtraction,
  unlockSlot: stationsActions.unlockSlot,
  getDynamicsById: stationsActions.getDynamicsById,
}

export default connect(mapState, actionCreators)(StationDetails)
